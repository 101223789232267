.primary{
  background-color: rgba(157, 0, 69, 1);
  border-style: none;
  color: #E6FEF0;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  padding: 20px 30px;
  transition: all 1s ease-out;
}

.primary:hover{
  background-color: #b5004f;
  cursor: pointer;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-out;
}

.secondary{
  background-color:#f8e7f1;
  border-style: none;
  color: #022310;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  padding: 20px 30px;
  text-transform: uppercase;
  transition: all 1s ease-out;
}

.secondary:hover{
  background-color: #FCF3F8;
  cursor: pointer;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-out;
}

.disabled{
  background-color:rgb(231, 230, 231);
  border-style: none;
  color: #adadad;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  margin-top: 40px;
  text-transform: uppercase;
  padding: 20px 30px;
  transition: all 1s ease-out;
}