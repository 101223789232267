.subfooter a {
  text-decoration: none;
  color: #F5FFF9;
}

.item {
  text-decoration: none;
  color: #F5FFF9;
}

.item {
  text-decoration: none;
  color: #F5FFF9;
}

.item:visited,
.item:hover,
.item:focus {
  color: #F5D1DE;
}

.subfooter {
  background-color: #143B35;
  color: #ECF3F8;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.item:not(:first-child){
  margin-top: 20px;
}

@media (min-width: 768px) {
  .container{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
   
  .item:not(:first-child){
    margin-top: 0;
    margin-left: 25px;
  }
}

@media (min-width: 1200px) {
  .item:not(:first-child){
    margin-left: 35px;
  }
}

@media (min-width: 1920px) {
  .container {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .item{
    font-size: 16px;
  }

  .item:not(:first-child){
    margin-left: 120px;
  }
}