.nav {
  position: fixed;
  z-index: 10000;
  top: 0;
  width: 100%;
  background-color:#F5FFF9;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #012310;  
  height: 60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: unset;
  align-items: center;
  justify-content: space-between;  
  position: relative; 
  padding-top: 0;
  padding-bottom: 0;
}

a.navbar-brand {
  padding: 0px 0px !important;
}

.offcanvas {
  background-color:#F5FFF9 !important;
}

.offcanvasBody{
  padding: 0px !important;
}

.services,
.about,
.expertise,
.caseStudies {
  color: #012310 !important;
  margin-right: 2px;
  cursor: pointer;
  height: 100%;
  text-decoration: none;
}

.services:hover,
.services:focus,
.about:hover,
.about:focus,
.expertise:hover,
.expertise:focus,
.caseStudies:hover,
.caseStudies:focus {
  text-shadow: 0 0 0.01px #012310
}

.itemnav:hover,
.itemnav:focus {
  color: #012310;
  text-shadow: 0 0 0.01px #012310
}

.itemnavmob:hover,
.itemnavmob:focus {
  color: #012310;
  text-shadow: 0 0 0.01px #012310
}

.active,
.activeservices {
  color: #012310 !important;
  margin-right: 2px;
  cursor: pointer;
  height: 100%;
  text-decoration: none;
  text-shadow: 0 0 0.01px #012310
}

.dropdownIcon,
.dropdownIconRotate {
  height: 5px;
  width: 8px;
}

.dropdownIconRotate {
  transform: rotate(180deg);
}

.logo {
  width: 27px;
  height: 40px;
}

.itemnav {
  color: #012310;
  text-decoration: none;
}

.itemnavmob{
  color: #012310;
  text-decoration: none;
  margin-right: 15px;
}

.navmenuS {
  display: flex;
  flex-direction: column;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.navmenu {
  display: none;
  align-items: center;
}

.itemmenuWrapper, 
.itemnavWrapper {
  text-decoration: none;
  color: #012310;
  cursor: pointer;
  padding: 15px 16px;
}

.itemnavWrapper:nth-child(2) {
  padding-bottom: 5px;
}

.itemmenuWrapper{
  padding-left: 40px;
}

.itemmenuWrapper:hover,
.itemmenuWrapper:focus{
  color: #012310;
  text-shadow: 0 0 0.01px #012310;
  background-color: #E6FEF1;
}

.itemnavWrapper .itemmenuWrapper:focus,
.itemnavWrapper .itemmenuWrapper:hover{
  color: #012310;
  text-shadow: 0 0 0.01px #012310;
  background-color: #E6FEF1;
}

.navmenuS .itemmenuWrapper {
  display: flex;  
  padding: 7px 30px;
}

.telWrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* kebab 2 styling */
.kebab {
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 10px;
}
.kebab figure {
  width: 5px;
  height: 5px;
  background: #9D0045;
  margin: 3px 0;
}
.middle {
  transition: all 0.25s cubic-bezier(0.72, 1.2, 0.71, 0.72);
  transform: scale(1);
  position: relative;
  box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
  -webkit-filter: blur(0.1px);
  filter: blur(0.1px);
}
.middle.active {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(5);
  transition: all 0.25s cubic-bezier(0.32, 2.04, 0.85, 0.54);
  box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
}
.cross {
  position: absolute;
  display: block;
  top: 40%;
  left: 48%;
  transform: translate(-50%, -50%) scale(0);
  color: #E6FEF1 !important;
  transition: all 0.2s cubic-bezier(0.72, 1.2, 0.71, 0.72);
  font-size: 20px;
  user-select: none;
  height: 14px;
  width: 100%;
  text-align: center;
  margin: 0;
}
.cross.active {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%) scale(1);
  transition: all 0.15s cubic-bezier(0.32, 2.04, 0.85, 0.54);
}

@media (min-width: 768px) {   
  .nav {
    height: 80px;
  }

  .dropDown, 
  .dropDown2,
                                .dropDown3,
                                .dropDown4 {
    position: absolute;
    top: 81px;
    z-index: 11;
    display: flex;
    flex-direction: column;
    background-color: #F5FFF9;
  }
  
                                                                .dropDown,
  .dropDown2,
  .dropDown3 {
    margin-left: 35px;
  }
  
  .navmenu {
    display: flex;    
    height: 100%;
  }
  
  .itemmenuWrapper{
    padding: 15px 30px;
    cursor: pointer;
    width: 280px;
  }

  .itemnav,
  .active,
  .about {
    margin-left: 25px;
  }

  .services, 
  .active, 
  .about, .activeservices {
    display: inline-flex;
    align-items: center;
    height: 82px;
  }
}

@media (min-width: 1200px) {
  .nav {
    font-size: 18px;
  }

  .logo {
    width: 156px;
    height: 50px;
  }

  .dropdownIcon,
  .dropdownIconRotate {
  height: 7px;
  width: 10px;
}

.itemnav,
.active,
.about {
  margin-left: 35px;
}
}